/* Smaller text size for mobile devices */
@media (max-width: 768px) {

    .league-summary table th {
        padding: 2px !important;
        font-size: 10px !important; 
    }
    .league-summary img {
        height: 40px !important;
        width: auto !important;
      }

      .name-td {
        font-size: 14px !important;
        
      }



  }

  table tr.league-team-non-cap-compliant td:nth-child(n+3) {
    background-color: #bd6868 !important;
  }

.icon img {
  width: 14px !important;
  height: 14px !important;
  display: block;
  max-width: 14px;
  max-height: 14px;
}