/* WeeklySchedule.css */

.weekly-schedule-container {
    display: flex;
    flex-direction: column;
  }
  
  .nav-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    padding: 10px 5px 0px;
  }
  
  /* WeeklySchedule.css */

.weekly-schedule-container {
    display: flex;
    flex-direction: column;
  }
  
  .nav-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
  }
  
  table.weekly_schedule {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }
  

  table.weekly_schedule,
  table.weekly_schedule td,
  table.weekly_schedule th {
    border: none !important;
  }

  table.weekly_schedule th, table.weekly_schedule td {
    border: 1px solid #dddddd;
    padding: 8px;
  }
  
  
  table.weekly_schedule td {
    font-size: 10px;
  }

  table.weekly_schedule img {
    width: 50px;
    max-height: 50px;
  }
  
  table.weekly_schedule td:last-child {
    font-size: 20px;
  }
  
  
  table.weekly_schedule tbody tr:nth-of-type(odd) td {
    background-color: transparent;
  }
  
  table.weekly_schedule tbody tr:nth-of-type(even) td {
    background-color: #f9f9f9;
  }
  
  table.weekly_schedule th {
    background-color: #ebebeb !important;
    border-bottom: 2px solid rgb(165, 165, 165) !important;
    border-top: none !important;
    font-size: 12px;
  }

  table.weekly_schedule td:first-child,
    table.weekly_schedule th:first-child {
    border-right: 2px solid rgb(165, 165, 165) !important;
}



/* Smaller text size for mobile devices */
@media (max-width: 768px) {

    table.weekly_schedule img {
        width: 40px;
        max-height: 40px;
      }
    
      table.weekly_schedule td {
        padding: 1px !important
      }

      table.weekly_schedule th {
        font-size: 8px;
      }
    
  }