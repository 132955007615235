/* Basic table formatting */

.table {
  width: 100%;
  border-collapse: collapse;
  padding: 10px !important;
}

.table,
.table td,
.table th {
  border: none !important;
}

.table tr{
  border-bottom: 2px solid rgb(182, 182, 182);
  border-top: 2px solid rgb(182, 182, 182);
}

.table td, .table th {
  border-left: none;
  border-right: none;
}

.table td, .table th {
  text-align: center;
  vertical-align: middle;
}

.name-td, .name-th {
  text-align: left !important;
  padding: 0px !important;
  vertical-align: middle !important;
  
}

.table th {
  background-color: #ebebeb !important;
  font-size: 80%;
  border-bottom: 2px solid rgb(165, 165, 165) !important;
  border-top: none !important;
}

.table tbody tr:nth-of-type(odd) td {
  background-color: transparent !important;
}

.table tbody tr:nth-of-type(even) td {
  background-color: #f9f9f9 !important;  /* Choose your shade of grey */
}

.table tr:last-child {
  border-bottom: 2px solid  rgb(165, 165, 165);
}



/* Body */
body {
  background-color: rgb(232, 232, 232) !important;
  padding-top: 100px;
  font-family: 'Open Sans', sans-serif !important;
}

/* Mobile styling */
@media (max-width: 768px) {
  body {
    padding-top: 0;
  }
}

h1 {
  font-size: 3em !important; /* Or any other size you'd like */
}

a {
  text-decoration: none !important;
}


/* Container */

.container {
  max-width: 900px !important;
  margin: 0 auto;
  padding: 0px !important; 
  background-color: rgb(255, 255, 255);
}

.page-content {
  padding: 10px;
}

.flex-container {
  display: flex;
  align-items: center;
}

.icon img {
  height: 13px;
  width: auto;
  margin-left: 8px;
}

