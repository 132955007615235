.team-title-container {
    display: flex;
    align-items: center;
  }
  
  .team-logo {
    border-radius: 100%;
    width: 180px;
    height: auto;
    margin-right: 16px;
  }


  .team_cap_summary_table {
    border-collapse: collapse;
    width: 95%;
    margin: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .team_cap_summary_table th, .team_cap_summary_table td {
    text-align: center;
    padding: 8px;
  }
  
  .team_cap_summary_table th {
    background-color: #f2f2f2;
  }
  
  .team_cap_summary_table tr:hover {
    background-color: #f5f5f5;
  }
  
  .team_cap_summary_table tr td,
  .team_cap_summary_table tr th:not(:first-child) {
    border-top: 2px solid rgb(182, 182, 182);
    border-bottom: 2px solid rgb(182, 182, 182);
  }

  .team_cap_summary_table td:nth-child(2),
  .team_cap_summary_table th:nth-child(2) {
  /* Your styles here */
  border-right: 2px solid rgb(182, 182, 182);
  font-weight: bold;
}


  .team_cap_summary_table tr td:first-child, .team_cap_summary_table tr th:first-child {
    /* Your styles here, for example: */
    font-weight: bold;
    background-color: #f5f5f5;
    border-right: 2px solid rgb(182, 182, 182);
  }
  
  .team_cap_summary_table thead tr:first-child th:first-child {
    /* Your styles here, for example: */
    background-color: transparent;
    border-top:  none;
  }


  .team_cap_summary_table td:last-child, .team_cap_summary_table th:last-child {
    font-weight: bold;
    border-left: 2px solid rgb(182, 182, 182);
    border-right: 2px solid rgb(182, 182, 182);
  }


  /* Apply a left border to all rows */
.team_cap_summary_table tbody tr {
    border-left: 2px solid rgb(182, 182, 182);
  }
  

  
  .table thead th:first-child {
    padding-left: 60px !important;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-container button {
    margin-bottom: 10px !important;
  }
  
    .icon img {
    height: 13px;
    width: auto;
    margin-left: 8px;
  }
  

/* Smaller text size for mobile devices */
@media (max-width: 768px) {
  .team_cap_summary_table {
    font-size: 12px; /* Set to desired text size for mobile */
  }
  .team-title-container h1{
    font-size: 2.5em !important;
  }
  .team-title-container img{
    width: 130px;
    height: auto;
  }
  .IR-table {
    font-size: 14px;
  }
}
  
  
  