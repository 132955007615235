#footer_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }
  
  #footer_box hr {
    width: 100%;
  }
  
  #footer_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  

  