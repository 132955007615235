.player-container {
    display: flex;
    align-items: center;
  }
  
  .player-image {
    border-radius: 25%;
    width: 50px;
    height: auto;
    margin-right: 10px;
    margin-left: 5px;
    padding: 5px;
  }
  
  .player-info {
    display: flex;
    align-items: center;
  }
  
  .player-details {
    font-size: 75%;
  }
  